// 2.0.4 Using tactile auth.service
// 2.0.5 Do not use clone request
// 2.0.6 Added service unavailable error
// 2.0.7 Reversed changes
// 2.0.8 Fix error.error.error (cannot find property of)
// 2.0.9 Redirect on "www" to ""
// 2.1.0 Special version for IAF

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { ToastrService } from 'ngx-toastr'
import { Observable, of, throwError } from 'rxjs'
import { catchError, filter, map, tap } from 'rxjs/operators'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    errorMessages = [
        `Whoops, we made a mistake`,
        `Darn it, we made a mistake`,
        `Oh blimey, we made a mistake`,
        `Mistakes have been made`,
        `Eh chief, come look at this`,
        `Houston, we have a problem`,
        `Houston, we've had a problem here`,
    ]

    constructor(private toastrService: ToastrService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = request.clone({
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        })

        return next.handle(authReq).pipe(
            tap((v) => this.versionCheck(v)),
            map((v) => this.map(v)),
            catchError((err) => this.catchErr(err)),
            filter((x) => x !== undefined)
        )
    }

    private versionHigher(a, b) {
        const aInt = parseInt(a)
        const bInt = parseInt(b)
        if (isNaN(a) || isNaN(b)) return a !== b
        return aInt > bInt
    }

    private versionCheck(response: HttpEvent<any>) {
        if (!(response instanceof HttpResponse)) return
        if (this['versionErrorShown']) return

        const headers = <HttpHeaders>response.headers
        const r = /[^0-9.]/g
        const vServer = headers.get('X-Package-Version').replace(r, '')
        const vLocal = environment.package.version.replace(r, '')
        const version = {
            server: {
                value: vServer,
                split: vServer.split('.'),
            },
            local: {
                value: vLocal,
                split: vLocal.split('.'),
            },
        }
        const vHigherMajor = this.versionHigher(version.server.split[0], version.local.split[0])
        const vHigherMinor = this.versionHigher(version.server.split[1], version.local.split[1])
        const vHigherPatch = this.versionHigher(version.server.split[2], version.local.split[2])
        const vHigher = vHigherMajor || vHigherMinor || vHigherPatch
        if (!vHigher) return // Major update reload immediatly

        console.log({
            vHigherMajor,
            vHigherMinor,
            vHigherPatch,
            vHigher,
            version,
        })

        this['versionErrorShown'] = true
        if (vHigherMajor) return this.refresh()
    }

    private map(response: HttpEvent<any>) {
        if (!(response instanceof HttpResponse)) return response
        return response
    }

    private catchErr(error: HttpErrorResponse) {
        if (error.error && error.error.decline === 'redirect-to-domain-without-www') {
            window.location.href = window.location.href.replace(/:\/\/www\./i, '://')
            return of(undefined)
        }

        // Network error
        if (error.status === 503) {
            this.toastrService.error(`Service is temporarily not available, please try again on a later moment`, `Service Unavailable`)
            return throwError(error)
        }

        if (error.status === 504) {
            this.toastrService.error(`Server unreachable, check your connection and please try again `, `Gateway timeout`)
            return throwError(error)
        }

        // 202 Accepted - Your request was fine, we are working on it. Tell where user can find respond
        //
        //
        // 400 - 499 (User error)
        // 401 Unauthorized - No auth present (please try again)
        // 403 Forbidden - I know who you are but you are not allowed
        // 406
        // 409 Conflict
        // 415
        // 429
        // 500 - 599 (Company errors)

        console.log({
            status: error.status,
            error: error.error ? error.error.error : error.error,
            decline: error.error ? error.error.decline : error.error,
            err: error,
        })

        // Show error
        if (error.error && error.error.error) {
            this.toastrService.error(error.error.message, this.errorMessages[Math.floor(Math.random() * this.errorMessages.length)])
        }

        if (error.error && error.error.decline) {
            // this.toastrService.error(error.error.message, this.errorMessages[Math.floor(Math.random() * this.errorMessages.length)]);
        }
        throw error
    }

    private refresh() {
        location.reload()
    }
}
