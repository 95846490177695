import '@angular/localize/init'
import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom } from '@angular/core'
import localeNL from '@angular/common/locales/nl'
import { environment } from './environments/environment'
import { AppComponent } from './app/app.component'
import { ToastrModule } from 'ngx-toastr'
import { SharedModule } from './app/shared/shared.module'
import { provideRouter, Routes } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { HttpConfigInterceptor } from './app/_services/interceptor.service'
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http'
import * as Sentry from '@sentry/angular-ivy'
import { registerLocaleData } from '@angular/common'
import { M3CalculatorPreComponent } from 'app/m3-calculator-pre/m3-calculator-pre.component'
import { i18nResolver } from 'app/_helpers/i18n.resolver'

// the second parameter 'nl' is optional
registerLocaleData(localeNL, 'nl')

const host = window.location.host
const s = {
    project: environment.package.name,
    version: environment.package.version,
    env: environment.production ? 'production' : 'develop',
    location: host.endsWith('localhost:4200') ? 'local' : 'remote',
}
Sentry.init({
    dsn: environment.sentry.dsn[s.project],
    environment: s.env,
    ignoreErrors: ['Non-Error exception captured'],
})

const routes: Routes = [
    { path: 'affiliate', loadChildren: () => import('./app/affiliate/affiliate.module').then((m) => m.AffiliateModule) },
    {
        path: 'form/m3-calculator',
        loadChildren: () => import('./app/m3-calculator/m3-calculator.module').then((m) => m.M3CalculatorModule),
    },
    {
        path: 'form/m3-calculator-pre/:language/:affiliate',
        component: M3CalculatorPreComponent,
        resolve: {
            language: i18nResolver,
        },
    },
    { path: 'form/viewing', loadChildren: () => import('./app/viewing/viewing.module').then((m) => m.ViewingModule) },
    { path: 'form/call', loadChildren: () => import('./app/call/call.module').then((m) => m.CallModule) },
    { path: 'form/contact', loadChildren: () => import('./app/contact/contact.module').then((m) => m.ContactModule) },
    { path: 'form/step', loadChildren: () => import('./app/removal-step/removal-step.module').then((m) => m.RemovalStepModule) },
    { path: 'form/removal', loadChildren: () => import('./app/removal-step/removal-step.module').then((m) => m.RemovalStepModule) },
    { path: 'form/lift-service', loadChildren: () => import('./app/lift-service/lift-service.module').then((m) => m.LiftServiceModule) },
    { path: 'form/boxes', loadChildren: () => import('./app/boxes/boxes.module').then((m) => m.BoxesModule) },
    {
        path: '**',
        redirectTo: 'form/removal/nl/redirect-not-found',
        pathMatch: 'full',
    },
]

if (environment.production) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            SharedModule,
            ToastrModule.forRoot({
                timeOut: 3000,
                progressBar: true,
                maxOpened: 3,
                positionClass: 'toast-top-center',
                preventDuplicates: true,
            })
        ),
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // showDialog: true,
            }),
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes),
    ],
}).catch((err) => console.log(err))
